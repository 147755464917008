import React from 'react';
// import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaFacebookF, FaLinkedin, FaGithub  } from 'react-icons/fa';


const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
          <a href= "https://www.linkedin.com/in/guang-xiang-teo-929783230/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
          </a>
        </div>
        <div>
          <a href="https://github.com/GxTeo" target="_blank" rel="noopener noreferrer">
                <FaGithub/>
          </a>
        </div>
    </div>

  )
}

export default SocialMedia