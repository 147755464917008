import React, { useState, useEffect } from 'react';
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { motion } from 'framer-motion';

import { AppWrap , MotionWrap} from '../../wrapper';
import { urlFor, client } from '../../client';

import './Projects.scss';



// This is a functional component named 'Projects'
const Projects = () => {

  // These are state variables. 'useState' is a Hook that lets you add React state to function components
  const [activeFilter, setactiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [projects, setProjects] = useState([]);
  const [filterProject, setFilterProject] = useState([]);

  // 'useEffect' is a Hook that lets you perform side effects in function components
  useEffect(() => {
    // This is a query string
    const query = '*[_type == "projects"]';

    // Fetching data from the client and setting it to the 'projects' and 'filterProject' state variables
    client.fetch(query)
      .then((data) => {
        setProjects(data);
        setFilterProject(data);
      })
  },[]) // The empty array means this effect will only run once, similar to 'componentDidMount' in classes

  // This function handles the project filter
  const handleProjectFilter = (item) => {
    setactiveFilter(item);
    setAnimateCard([{y: 100, opacity: 0}]);

    setTimeout(() => {
      setAnimateCard([{ y:0, opacity: 1}]);

      // If the item is 'All', set 'filterProject' to all projects, else filter the projects based on the item
      if(item==='All'){
        setFilterProject(projects);
      } else {
        setFilterProject(projects.filter((project) => project.tags.includes(item)))
      }
    })
  }
  
  // The component returns this JSX
  return (
    <>
      <h2 className="head-text"> <span> My Projects </span> </h2>
      <div className="app__project-filter" >
        {['Data Analytics', 'Computer Vision', 'Natural Language Processing', 'Web App', 'All'].map((item, index)=> (
          <div
            key={index}
            onClick={() => handleProjectFilter(item)}
            className={`app__project-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : '' }`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__project-portfolio"
        >
          {filterProject.map((project, index) =>
            <div className="app__project-item app__flex" key={index}>
              <div className="app__project-img app__flex">
                <img src={urlFor(project.imgUrl)} alt={project.title}></img>
                <motion.div
                  whileHover={{opacity: [0, 1]}}
                  // Show the elements one by one
                  transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5}}
                  className="app__project-hover app__flex"
                >
                  <a href={project.projectLink} target= "_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{scale: [0, 1]}}
                      whileHover={{opacity: [1, 1]}}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                      >
                        <AiFillEye></AiFillEye>
                    </motion.div>
                  </a>
                  <a href={project.githubLink} target= "_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{scale: [0, 1]}}
                      whileHover={{opacity: [1, 1]}}
                      // Show the elements one by one
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                      >
                        <AiFillGithub></AiFillGithub>
                    </motion.div>
                  </a>
                </motion.div>
              </div>
              <div className="app__project-content app__flex">
                <h4 className="bold-text">{project.title}</h4>
                <p className="p-text" style={{ marginTop: 10}}>{project.description}</p>

                <div className="app__project-tag app__flex">
                  <p className="p-text">{project.tags[0]}</p>
                </div>
              </div>
            </div>   
          )}
      </motion.div>

    </>
  )
}

// 'AppWrap' is a higher-order component that wraps the 'Projects' component
export default AppWrap(MotionWrap(Projects, 'app__projects'),
              'projects', "app__primarybg")
