import React, { useState } from 'react';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import emailjs from '@emailjs/browser';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ from_name: '', email: '', message: ''})
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { from_name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData({...formData, [name]: value })
  }

  const handleSubmit = (e) => {

    e.preventDefault(); // Prevents default refresh by the browser
    setLoading(true);
    
    emailjs.sendForm('service_h6uzhlc', 'template_n35lwpq', e.target, 'fwjrpUoHL5DcxZ-rK')
     .then((result) => {
      console.log(result.text);
      setFormData({
        from_name: '',
        email: '',
        message: '',
      });
    }, (error) => {
      console.log(error.text);
    });

    setIsFormSubmitted(true);

  };
  return (
    <>
      <h2 className='head-text'> <span>Chat With Me </span></h2>
        <div className="app__footer-cards">
          <div className="app__footer-card">
            <img src={images.email} alt="email" />
            <a href="mailto:guangxiang_99@outlook.sg" className="p-text">guangxiang_99@outlook.sg</a>
          </div>
        </div>
        {!isFormSubmitted ?
          <form className='app__footer-form app__flex' onSubmit={handleSubmit}>
            <div className="app__flex">
              <input className="p-text" type="text" placeholder="Your Name" name="from_name" style={{ color: '#000000'}} value={from_name} onChange={handleChangeInput}/>
            </div>
            <div className="app__flex">
              <input className="p-text" type="email" placeholder="Your Email" name="email" style={{ color: '#000000'}} value={email} onChange={handleChangeInput}/>
            </div>
            <div>
              <textarea
                className="p-text"
                placeholder="Your Message"
                style={{ color: '#000000'}}
                value={message}
                name="message"
                onChange={handleChangeInput}>
              </textarea>
            </div>
            <button type="submit" className="p-text">{loading ? 'Sending' : 'Send Message'}</button>
          </form>
        : <div>
           <h3 className="head-text" >Thank you for getting in touch!</h3>
        </div>
      }
    </>
    )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__primarybg'
)