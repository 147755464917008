import React, { useState, useEffect } from 'react';

import { AppWrap, MotionWrap } from '../../wrapper';

import { motion } from 'framer-motion';

import { urlFor, client } from '../../client';

import './About.scss';

/* Static data */
// const abouts = [
//     { title: 'Data Science', description: 'I am an aspiring Data Scientist', imgUrl: images.about01},
//     { title: 'Web Development', description: 'I am a beginner in Web Development :P', imgUrl: images.about02},
// ];

const About = () => {
    const [abouts, setAbouts] = useState([]);

    useEffect(() => {
        const query = '*[_type == "abouts"]';

        client.fetch(query)
            .then((data)=> setAbouts(data))
    }, []);

    return (
        <>
            <h2 className="head-text">
                <span>About Me</span>
            </h2>
            <div className="app__profiles" >
                {abouts.map((about, index)=> (
                    <motion.div
                        whileInView={{ opacity:  1}}
                        whileHover={{ scale: 1.1 }}
                        transition={{ duration: 0.5, type: 'tween' }}
                        className='app__profile-item'
                        key={about.title + index}
                    >
                        <img src={urlFor(about.imgUrl)} alt={about.title} />
                        <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
                        <h2 className="bold-text" style={{ marginTop: 10 }}>{about.description}</h2>
                    </motion.div>
                ))}

            </div>
        </>
    )
}

export default AppWrap(
    MotionWrap(About, 'app__about')
    , 'about',
    "app__graybg");